/*==========Variable starts here==========*/

$primary: #00684d;
$bg-white: #fff;
$profile-bg: #f7f7f7;
$bg-gray: #ddd;
$danger:red;

/*==========Variable ends here==========*/


/*==========Mixin starts here==========*/
@mixin centerFlex {
  display: flex;
  justify-content: center;
}

@mixin flexbox {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #ddd;
  border-style: dashed;
  text-align: center;
  padding:5rem 1rem;
}

@mixin model{
  position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    word-wrap:break-word;
   background-color:#fff;  
   padding:20px;   
}

/*==========Mixin ends here==========*/